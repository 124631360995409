@import url(https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital,wght@0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap);
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Source Serif Pro", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main::-webkit-scrollbar {
  display: none;
}

.main {
  background-color: #fffbf8;
  width: 80%;
  height: 88vh;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
}

.header {
  height: 73vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.header h1 {
  text-align: center;
  font-size: 85px;
  color: #c3400d;
  font-style: italic;
  -webkit-animation: fadein 0.8s;
          animation: fadein 0.8s;
  margin: 0;
}

@-webkit-keyframes fadein {
  0% {
    margin-bottom: -300px;
    opacity: 0;
  }
  100% {
    margin-bottom: 0;
    margin-bottom: initial;
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    margin-bottom: -300px;
    opacity: 0;
  }
  100% {
    margin-bottom: 0;
    margin-bottom: initial;
    opacity: 1;
  }
}

.header h2 {
  color: #c3400d;
  font-size: 90px;
  font-weight: 300;
  text-align: center;
  font-style: italic;
  -webkit-transform: translateY(-50%, -50%);
          transform: translateY(-50%, -50%);
  -webkit-animation: fadespan 1s;
          animation: fadespan 1s;
  margin-top: 150px;
  position: absolute;
}

@-webkit-keyframes fadespan {
  0% {
    margin-top: 600px;
    opacity: 0;
  }
  100% {
    margin-right: 0;
    opacity: 1;
  }
}

@keyframes fadespan {
  0% {
    margin-top: 600px;
    opacity: 0;
  }
  100% {
    margin-right: 0;
    opacity: 1;
  }
}

.text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.text p {
  width: 45%;
  color: #4c112d;
  text-align: center;
  font-size: 30px;
}

.scroll {
  display: none;
}

.gifts {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  position: relative;
}

.gifts img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gifts button {
  font-family: "Source Serif Pro", serif;
  font-weight: 600;
  font-size: 18px;
  border-radius: 50px;
  height: 50px;
  width: 50px;
  border: none;
  background-color: rgba(128, 128, 128, 0.281);
  position: absolute;
  transition: 0.5s;
}

.gifts button:hover {
  background-color: white;
  cursor: pointer;
  transition: 0.5s;
}

.gifts button:nth-child(2) {
  top: 30px;
  left: 180px;
  margin-left: 70px;
}

.gifts button:nth-child(3) {
  top: 130px;
  right: 50px;
  margin-top: 55px;
}

.gifts button:nth-child(4) {
  bottom: 75px;
  margin-bottom: 50px;
  left: 55px;
  margin-left: 70px;
}

.gifts button:nth-child(5) {
  bottom: 140px;
  right: 140px;
  margin-right: 70px;
}

a {
  text-decoration: none;
}

.center {
  position: absolute;
  margin: 0 auto;
}

.center .centerblock {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.center .centerblock .centertitle {
  font-size: 45px;
  font-weight: 500;
  -webkit-animation: 0.8s centertitle forwards ease-in-out;
          animation: 0.8s centertitle forwards ease-in-out;
  position: absolute;
  text-align: center;
  width: 550px;
}

@-webkit-keyframes centertitle {
  0% {
    margin-bottom: -200px;
    opacity: 0;
  }
  100% {
    margin-top: -90px;
    opacity: 1;
  }
}

@keyframes centertitle {
  0% {
    margin-bottom: -200px;
    opacity: 0;
  }
  100% {
    margin-top: -90px;
    opacity: 1;
  }
}

/*blocktwo*/
.center .centerblock .centertitle.blocktwo {
  font-size: 40px;
  font-weight: 500;
  position: absolute;
  text-align: center;
  width: 380px;
  -webkit-animation: 0.8s centertitleblocktwo forwards ease-in-out;
          animation: 0.8s centertitleblocktwo forwards ease-in-out;
}

@-webkit-keyframes centertitleblocktwo {
  0% {
    margin-bottom: -200px;
    margin-right: -120px;
    opacity: 0;
  }
  100% {
    margin-top: -90px;
    margin-right: 0;
    opacity: 1;
  }
}

@keyframes centertitleblocktwo {
  0% {
    margin-bottom: -200px;
    margin-right: -120px;
    opacity: 0;
  }
  100% {
    margin-top: -90px;
    margin-right: 0;
    opacity: 1;
  }
}

.center .centerblock .centerimages {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 200px;
}

.center .centerblock .centerimages img {
  width: 200px;
  height: 200px;
  margin: 15px 10px;
  transition: 0.5s;
  margin-bottom: -30px;
  border-radius: 10px;
}

.center .centerblock .centerimages .firstimage {
  width: 200px;
  height: 200px;
  margin: 15px 10px;
  position: absolute;
  right: 0;
  transition: 0.5s;
  -webkit-animation: 1s firstimage forwards ease-in-out;
          animation: 1s firstimage forwards ease-in-out;
}

@-webkit-keyframes firstimage {
  0% {
    margin-top: 300px;
    opacity: 0;
  }
  100% {
    margin-top: -50px;
    opacity: 1;
  }
}

@keyframes firstimage {
  0% {
    margin-top: 300px;
    opacity: 0;
  }
  100% {
    margin-top: -50px;
    opacity: 1;
  }
}

.center .centerblock .centerimages .secondimage {
  width: 200px;
  height: 200px;
  margin: 15px 10px;
  transition: 0.5s;
  position: absolute;
  left: 0;
  -webkit-animation: 1.2s secondimage forwards ease-in-out;
          animation: 1.2s secondimage forwards ease-in-out;
}

@-webkit-keyframes secondimage {
  0% {
    margin-top: 300px;
    opacity: 0;
  }
  100% {
    margin-top: -60px;
    opacity: 1;
  }
}

@keyframes secondimage {
  0% {
    margin-top: 300px;
    opacity: 0;
  }
  100% {
    margin-top: -60px;
    opacity: 1;
  }
}

/*secondblock*/

.center .centerblock .centerimages .firstimage.secondblock {
  width: 200px;
  height: 150px;
  margin: 15px 10px;
  position: absolute;
  right: 0;
  z-index: 1;
  transition: 0.5s;
  -webkit-animation: 1s firstimageblocktwo forwards ease-in-out;
          animation: 1s firstimageblocktwo forwards ease-in-out;
}

@-webkit-keyframes firstimageblocktwo {
  0% {
    margin-top: 300px;
    right: -200px;
    opacity: 0;
  }
  100% {
    margin-top: -30px;
    right: -40px;
    opacity: 1;
  }
}

@keyframes firstimageblocktwo {
  0% {
    margin-top: 300px;
    right: -200px;
    opacity: 0;
  }
  100% {
    margin-top: -30px;
    right: -40px;
    opacity: 1;
  }
}

.center .centerblock .centerimages .secondimage.secondblock {
  width: 260px;
  height: 140px;
  margin: 15px 10px;
  transition: 0.5s;
  position: absolute;
  left: 0;
  -webkit-animation: 1s secondimageblock2 forwards ease-in-out;
          animation: 1s secondimageblock2 forwards ease-in-out;
}

@-webkit-keyframes secondimageblock2 {
  0% {
    margin-top: 350px;
    left: 150px;
    opacity: 0;
  }
  100% {
    margin-top: 0px;
    left: -10px;
    opacity: 1;
  }
}

@keyframes secondimageblock2 {
  0% {
    margin-top: 350px;
    left: 150px;
    opacity: 0;
  }
  100% {
    margin-top: 0px;
    left: -10px;
    opacity: 1;
  }
}

/*thirdblock*/
.center .centerblock .centerimages .firstimagetwo.thirdblock {
  width: 350px;
  height: 180px;
  margin: 15px 10px;
  position: absolute;
  transition: 0.5s;
  -webkit-animation: 1s firstimage forwards ease-in-out;
          animation: 1s firstimage forwards ease-in-out;
}

/*fourth*/
.center .centerblock .centerimages .firstimage.fourth {
  width: 250px;
  height: 150px;
  margin: 15px 10px;
  position: absolute;
  transition: 0.5s;
  -webkit-animation: 1s firstimageblocktwo forwards ease-in-out;
          animation: 1s firstimageblocktwo forwards ease-in-out;
}

.center .centerblock .centerimages .secondimage.fourth {
  width: 260px;
  height: 140px;
  margin: 15px 10px;
  transition: 0.5s;
  position: absolute;
  left: 0;
  -webkit-animation: 0.8s secondimageblock2 forwards ease-in-out;
          animation: 0.8s secondimageblock2 forwards ease-in-out;
}

@keyframes secondimageblock2 {
  0% {
    margin-top: 350px;
    left: 150px;
    opacity: 0;
  }
  100% {
    margin-top: -60px;
    left: -10px;
    opacity: 1;
  }
}

@media (max-width: 800px) {
  .main {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: visible;
    overflow: initial;
    overflow-x: visible;
    overflow-x: initial;
    overflow-y: visible;
    overflow-y: initial;
    -ms-overflow-style: none;
  }
  .header {
    width: 100%;
  }
  .header h1 {
    font-size: 70px;
    line-height: 65px;
  }
  .header h2 {
    margin-top: 150px;
    font-size: 65px;
  }
  .text p {
    width: 75%;
    font-size: 19px;
  }
  .scroll {
    display: flex;
    text-transform: uppercase;
    font-size: 15px;
    color: #4c112d;
    text-align: center;
    margin-top: 170px;
    -webkit-animation: scrollanimation 3s infinite ease-in-out;
            animation: scrollanimation 3s infinite ease-in-out;
  }
  .center .centerblock .centertitle {
    font-size: 25px;
    width: 300px;
  }
  .center .centerblock .centertitle.blocktwo {
    font-size: 25px;
    width: 300px;
  }
  .center .centerblock .centerimages .secondimage {
    width: 130px;
    height: 140px;
  }
  .center .centerblock .centerimages .firstimage {
    width: 150px;
    height: 140px;
  }
  .center .centerblock .centerimages .firstimage.secondblock {
    width: 160px;
    height: 120px;
  }
  .center .centerblock .centerimages .secondimage.secondblock {
    width: 190px;
    height: 120px;
  }
  .center .centerblock .centerimages .firstimage.thirdblock {
    width: 300px;
    height: 150px;
  }
  .center .centerblock .centerimages .firstimage.fourth {
    width: 170px;
    height: 100px;
  }
  .gifts > img {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    object-fit: cover;
    height: auto;
    width: 100vh;
    display: block;
  }
  .gifts button:nth-child(2) {
    top: 30px;
    left: 40px;
  }

  .gifts button:nth-child(3) {
    top: 130px;
    right: 50px;
  }

  .gifts button:nth-child(4) {
    bottom: -30px;
    left: 110px;
  }

  .gifts button:nth-child(5) {
    bottom: 50px;
    right: 40px;
  }
}

@-webkit-keyframes scrollanimation {
  0% {
    bottom: 20px;
  }
  50% {
    bottom: 35px;
  }
  100% {
    bottom: 20px;
  }
}

@keyframes scrollanimation {
  0% {
    bottom: 20px;
  }
  50% {
    bottom: 35px;
  }
  100% {
    bottom: 20px;
  }
}

/*Lockscreen */
.lockscreen {
  background-color: #fffbf8;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Source Serif Pro", serif;
}

.lockscreenanimation {
  -webkit-animation: fadeaway 1.3s ease-in-out forwards;
          animation: fadeaway 1.3s ease-in-out forwards;
  background-color: #fffbf8;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Source Serif Pro", serif;
}

@-webkit-keyframes fadeaway {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeaway {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.lockscreen input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #707070;
  width: 300px;
  height: 29px;
  font-family: "Source Serif Pro", serif;
  text-align: center;
  font-size: 24px;
}

.lockscreen input::-webkit-input-placeholder {
  opacity: 0.22;
  color: #c3400d;
  font-size: 24px;
  text-align: center;
}

.lockscreen input:-ms-input-placeholder {
  opacity: 0.22;
  color: #c3400d;
  font-size: 24px;
  text-align: center;
}

.lockscreen input::placeholder {
  opacity: 0.22;
  color: #c3400d;
  font-size: 24px;
  text-align: center;
}

.lockscreen input:focus {
  outline: none;
}

.lockscreenanimation input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #707070;
  width: 300px;
  height: 29px;
  font-family: "Source Serif Pro", serif;
  text-align: center;
  font-size: 24px;
}

.lockscreenanimation input::-webkit-input-placeholder {
  opacity: 0.22;
  color: #c3400d;
  font-size: 24px;
  text-align: center;
}

.lockscreenanimation input:-ms-input-placeholder {
  opacity: 0.22;
  color: #c3400d;
  font-size: 24px;
  text-align: center;
}

.lockscreenanimation input::placeholder {
  opacity: 0.22;
  color: #c3400d;
  font-size: 24px;
  text-align: center;
}

.lockscreenanimation input:focus {
  outline: none;
}

.submit {
  font-size: 30px;
  padding: 10px;
  font-style: italic;
}

.cal2action {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.cal2action p {
  font-size: 30px !important;
  font-family: "Source Serif Pro", serif;
  text-align: center;
}

.cal2action img {
  width: auto;
  height: 32px;
  z-index: 1;
  position: absolute;
}

.cal2action img:nth-child(2) {
  top: 0;
  left: -30px;
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
}

.cal2action img:nth-child(3) {
  bottom: 0;
  left: 0;
}

.cal2action img:nth-child(4) {
  top: 0;
  right: 0;
}

.cal2action img:nth-child(5) {
  bottom: 0;
  right: 0;
}

.giftone {
  position: absolute;
  height: 150px;
  width: 150px;
  top: 0;
  left: 0;
}

.gifttwo {
  position: absolute;
  height: 150px;
  width: 150px;
  top: 0;
  right: 0;
}

.giftthree {
  position: absolute;
  height: 150px;
  width: 150px;
  bottom: 0;
  left: 0;
}

.giftfour {
  position: absolute;
  height: 150px;
  width: 150px;
  bottom: 0;
  right: 0;
}

